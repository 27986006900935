"use client";

import React from "react";
import { Mic } from "lucide-react";

const RefundPolicy = () => {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-sans">
      {/* Header */}
      <header className="bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <a href="/" className="flex items-center">
            <Mic className="size-8 text-indigo-600" />
            <span className="ml-2 text-2xl font-bold text-indigo-600">VIDOREN</span>
          </a>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-indigo-600">Refund Policy</h1>
        
        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Refund Eligibility</h2>
            <p className="mb-4">
              Vidoren offers refunds under specific conditions. To be eligible for a refund:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>The refund request must be made within 7 days of purchase</li>
              <li>The service usage must be minimal (less than 10% of the plan's total processing minutes)</li>
              <li>The account must not have violated our Terms of Service</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Non-Refundable Circumstances</h2>
            <p className="mb-4">
              Refunds will not be provided if:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>You have used a significant portion of the service's processing minutes</li>
              <li>The 7-day refund window has expired</li>
              <li>You have violated our Terms of Service</li>
              <li>You have received previous refunds</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Refund Process</h2>
            <p className="mb-4">
              To request a refund:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Contact our support team at help.vidoren@gmail.com</li>
              <li>Include your account email and reason for the refund</li>
              <li>Allow up to 5-7 business days for review and processing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Company Discretion</h2>
            <p className="mb-4">
              All refund decisions are at Vidoren's sole discretion. We reserve the right to reject refund requests that do not meet our eligibility criteria or if we suspect abuse of our refund policy.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Service Costs</h2>
            <p className="mb-4">
              Please note that Vidoren incurs real costs in providing our AI-powered video processing services. Once you have used our services, these costs cannot be recovered, which is why we cannot provide refunds after significant usage.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Contact Information</h2>
            <p className="mb-4">
              For questions about our refund policy, please contact us at:{" "}
              <a href="mailto:help.vidoren@gmail.com" className="text-indigo-600 hover:text-indigo-800">
                help.vidoren@gmail.com
              </a>
            </p>
          </section>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Vidoren. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default RefundPolicy;