import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Mic, Globe, Zap, Edit3, Clock, Sparkles, Shield, Play, ChevronRight, Check, Video, Languages } from "lucide-react";
import RefundPolicy from './refund/RefundPolicy';
import TermsOfService from './terms-of-services/TermsOfServices';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';


const LandingPage = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 510);
  const waitlistRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 510);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbynyh0BOR7jrMxabgcSCLkBmzxeGXi_qkSQvL5_Ts56r7RdSVYRiX0IwfLP6uXWfG9V/exec',
        {
          method: 'POST',
          body: new FormData(e.target),
        }
      );

      if (response.ok) {
        setSubmitMessage('Thank you for joining the waitlist!');
        setEmail('');
      } else {
        setSubmitMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    }

    setIsSubmitting(false);
  };

  const scrollToWaitlist = () => {
    waitlistRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <Routes>
        <Route path="/refund" element={<RefundPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/" element={
          <div className="min-h-screen bg-white text-gray-800 font-sans">
            {/* Header */}
            <header className="bg-white shadow-sm py-4 sticky top-0 z-50">
              <div className="container mx-auto px-4 max-w-5xl flex justify-between items-center">
                <div className="flex items-center">
                  <Mic className="size-6 text-indigo-600" />
                  <span className="ml-2 text-xl font-bold text-indigo-600">VIDOREN</span>
                </div>
                <nav className="hidden md:flex space-x-4">
                  <a href="#features" className="text-sm text-gray-600 hover:text-indigo-600">Features</a>
                  <a href="#how-it-works" className="text-sm text-gray-600 hover:text-indigo-600">How It Works</a>
                  <a href="#pricing" className="text-sm text-gray-600 hover:text-indigo-600">Pricing</a>
                  <a href="/blogs" className="text-sm text-gray-600 hover:text-indigo-600">Blog</a>
                </nav>
                <button 
                  className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm hover:bg-indigo-700 transition duration-300"
                  onClick={scrollToWaitlist}
                >
                  Join Waitlist
                </button>
              </div>
            </header>

            <main>
              {/* Hero Section */}
              <section className="bg-gradient-to-r from-indigo-50 to-blue-50 py-16">
                <div className="container mx-auto px-4 max-w-5xl flex flex-col md:flex-row items-center">
                  <div className="md:w-1/2 mb-8 md:mb-0">
                    <h1 className="text-4xl font-bold mb-4 text-indigo-600">Captivate Your Audience with AI-Powered Captions</h1>
                    <p className="text-lg mb-6 text-gray-600">
                      Transform your videos into engaging content with Vidoren's cutting-edge AI caption technology. Join our exclusive waitlist today!
                    </p>
                    <button
                      className="bg-indigo-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300 flex items-center"
                      onClick={scrollToWaitlist}
                    >
                      Join Waitlist Now <ChevronRight className="ml-2 size-4" />
                    </button>
                  </div>
                  <div className="md:w-1/2 md:pl-8">
                    <img 
                      src="/long.gif" 
                      alt="Vidoren AI Captioning" 
                      className="rounded-lg shadow-xl w-full max-w-md mx-auto"
                    />
                  </div>
                </div>
              </section>

              {/* Features Section */}
              <section id="features" className="py-16">
                <div className="container mx-auto px-4 max-w-5xl">
                  <h2 className="text-2xl font-bold mb-8 text-center text-indigo-600">Supercharge Your Content with Vidoren</h2>
                  <div className="grid md:grid-cols-3 gap-6">
                    <FeatureCard 
                      icon={<Globe className="size-10 text-indigo-600" />}
                      title="Multi-Language Support"
                      description="Generate captions in over 50 languages, expanding your global reach."
                    />
                    <FeatureCard 
                      icon={<Zap className="size-10 text-indigo-600" />}
                      title="Lightning-Fast Processing"
                      description="Get your captions in minutes, not hours, with our advanced AI technology."
                    />
                    <FeatureCard 
                      icon={<Edit3 className="size-10 text-indigo-600" />}
                      title="Easy Editing"
                      description="Fine-tune your captions with our intuitive editing interface."
                    />
                    <FeatureCard 
                      icon={<Sparkles className="size-10 text-indigo-600" />}
                      title="Style Customization"
                      description="Choose from a variety of caption styles to match your brand."
                    />
                    <FeatureCard 
                      icon={<Clock className="size-10 text-indigo-600" />}
                      title="Time-Saving Automation"
                      description="Automate your workflow and focus on creating great content."
                    />
                    <FeatureCard 
                      icon={<Shield className="size-10 text-indigo-600" />}
                      title="Privacy Protection"
                      description="Your content is safe with our secure, encrypted platform."
                    />
                  </div>
                </div>
              </section>

              {/* How It Works Section */}
              <section id="how-it-works" className="bg-gray-50 py-16">
                <div className="container mx-auto px-4 max-w-5xl">
                  <h2 className="text-2xl font-bold mb-8 text-center text-indigo-600">How Vidoren Works</h2>
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                      <img 
                        src="/short.gif" 
                        alt="Vidoren Workflow" 
                        className="rounded-lg shadow-lg w-full max-w-sm mx-auto"
                      />
                    </div>
                    <div className="md:w-1/2 md:pl-8">
                      <ol className="space-y-4">
                        <li className="flex items-center">
                          <div className="bg-indigo-600 text-white rounded-full size-6 flex items-center justify-center mr-3 text-sm">1</div>
                          <p className="text-base">Upload your video to Vidoren</p>
                        </li>
                        <li className="flex items-center">
                          <div className="bg-indigo-600 text-white rounded-full size-6 flex items-center justify-center mr-3 text-sm">2</div>
                          <p className="text-base">Our AI generates accurate captions</p>
                        </li>
                        <li className="flex items-center">
                          <div className="bg-indigo-600 text-white rounded-full size-6 flex items-center justify-center mr-3 text-sm">3</div>
                          <p className="text-base">Edit and customize your captions</p>
                        </li>
                        <li className="flex items-center">
                          <div className="bg-indigo-600 text-white rounded-full size-6 flex items-center justify-center mr-3 text-sm">4</div>
                          <p className="text-base">Download or publish directly to your platform</p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              {/* Pricing Section */}
              <section id="pricing" className="py-16">
                <div className="container mx-auto px-4 max-w-5xl">
                  <h2 className="text-2xl font-bold mb-8 text-center text-indigo-600">Exclusive Launch Pricing</h2>
                  
                  <div className="flex items-center space-x-2 mb-6 justify-center">
                    <label className="inline-flex items-center cursor-pointer">
                      <span className="text-lg font-medium text-gray-900 p-2">Monthly</span>
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={isAnnual}
                        onChange={() => {setIsAnnual(!isAnnual)}}
                      />
                      <div className="relative w-11 h-6 bg-gray-800 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:size-5 after:transition-all peer-checked:bg-indigo-800"></div>
                      <span className="ml-3 text-lg font-medium text-gray-900">Annual (20% off)</span>
                    </label>
                  </div>

                  <div className="grid md:grid-cols-3 gap-6 max-w-4xl mx-auto">
                    <PricingCard 
                      title="Starter"
                      price={isAnnual ? "$199" : "$21"}
                      interval={isAnnual ? "year" : "month"}
                      features={[
                        "30 minutes of video processing",
                        "Basic caption styles",
                        "2 language translations",
                        "Email support"
                      ]}
                      ctaText="Join Waitlist"
                      onClick={scrollToWaitlist}
                    />
                    <PricingCard 
                      title="Pro"
                      price={isAnnual ? "$372" : "$39"}
                      interval={isAnnual ? "year" : "month"}
                      features={[
                        "90 minutes of video processing",
                        "Advanced caption styles",
                        "10 language translations",
                        "Priority support"
                      ]}
                      ctaText="Join Waitlist"
                      onClick={scrollToWaitlist}
                      highlighted={true}
                    />
                    <PricingCard 
                      title="Premium"
                      price={isAnnual ? "$949" : "$99"}
                      interval={isAnnual ? "year" : "month"}
                      features={[
                        "300 minutes of video processing",
                        "Custom caption styles",
                        "All language translations",
                        "24/7 dedicated support"
                      ]}
                      ctaText="Contact Sales"
                      onClick={() => window.location.href = '/contact'}
                    />
                  </div>
                  <p className="text-center text-gray-600 mt-6 text-sm">(*) Tax will be included in final amount as per country of user.</p>
                </div>
              </section>

              {/* Waitlist Form Section */}
              <section id="waitlist-form" className="bg-indigo-600 text-white py-16" ref={waitlistRef}>
                <div className="container mx-auto px-4 max-w-5xl text-center">
                  <h2 className="text-2xl font-bold mb-4">Be Among the First to Experience Vidoren</h2>
                  <p className="text-lg mb-6">
                    Join our exclusive waitlist and get early access to our AI-powered captioning tool.
                  </p>
                  <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                    <input
                      type="email"
                      name="Email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="w-full px-4 py-2 rounded-full mb-4 text-gray-800"
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-white text-indigo-600 px-6 py-2 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 w-full"
                    >
                      {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                    </button>
                    {submitMessage && (
                      <p className="mt-4 text-sm font-semibold">{submitMessage}</p>
                    )}
                  </form>
                </div>
              </section>
            </main>

            <footer className="bg-gray-800 text-white py-10">
              <div className="container mx-auto px-4 max-w-5xl">
                <div className="grid md:grid-cols-3 gap-6">
                  <div>
                    <h3 className="text-lg font-semibold mb-3">Vidoren</h3>
                    <p className="text-sm">Empowering content creators worldwide with AI-driven captions.</p>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-3">Quick Links</h3>
                    <ul className="space-y-1 text-sm">
                      <li><a href="#features" className="hover:text-indigo-400">Features</a></li>
                      <li><a href="#how-it-works" className="hover:text-indigo-400">How It Works</a></li>
                      <li><a href="#pricing" className="hover:text-indigo-400">Pricing</a></li>
                      <li><a href="/blogs" className="hover:text-indigo-400">Blog</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-3">Legal</h3>
                    <ul className="space-y-1 text-sm">
                      <li><Link to="/terms" className="hover:text-indigo-400">Terms of Service</Link></li>
                      <li><Link to="/privacy" className="hover:text-indigo-400">Privacy Policy</Link></li>
                      <li><Link to="/refund" className="hover:text-indigo-400">Refund Policy</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="mt-6 pt-6 border-t border-gray-700 text-center text-sm">
                  <p>&copy; 2024 Vidoren. All rights reserved.</p>
                </div>
              </div>
            </footer>
          </div>
        } />
      </Routes>
    </Router>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
    <div className="mb-3">{icon}</div>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-sm">{description}</p>
  </div>
);

const PricingCard = ({ title, price, interval, features, ctaText, onClick, highlighted }) => (
  <div className={`bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300 ${highlighted ? 'border-2 border-indigo-600' : ''}`}>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-sm">{price} {interval}</p>
    <ul className="mt-4 space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-sm">
          <Check className="text-green-500 mr-2" size={16} />
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={onClick}
      className={`mt-6 w-full py-2 px-4 rounded-full text-sm font-semibold transition duration-300 ${
        highlighted
          ? 'bg-indigo-600 text-white hover:bg-indigo-700'
          : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
      }`}
    >
      {ctaText}
    </button>
  </div>
);

export default LandingPage;
