"use client";

import React from "react";
import { Mic } from "lucide-react";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-sans">
      {/* Header */}
      <header className="bg-white shadow-sm py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <a href="/" className="flex items-center">
            <Mic className="size-8 text-indigo-600" />
            <span className="ml-2 text-2xl font-bold text-indigo-600">VIDOREN</span>
          </a>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-indigo-600">Terms of Service</h1>
        
        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="mb-4">
              By accessing and using Vidoren's services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
            <p className="mb-4">
              Vidoren provides AI-powered video captioning services for social media content. Our services include automatic caption generation, translation, and styling options for platforms like TikTok, Instagram, and YouTube.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
            <p className="mb-4">
              To use our services, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Subscription and Payments</h2>
            <p className="mb-4">
              We offer various subscription plans with different features and processing limits. Payments are processed securely, and subscriptions will automatically renew unless cancelled.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Content Guidelines</h2>
            <p className="mb-4">
              Users must not upload content that:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Infringes on intellectual property rights</li>
              <li>Contains explicit or inappropriate material</li>
              <li>Violates any applicable laws or regulations</li>
              <li>Promotes hate speech or discrimination</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Service Availability</h2>
            <p className="mb-4">
              While we strive for 100% uptime, we cannot guarantee uninterrupted access to our services. We reserve the right to modify or discontinue services with reasonable notice.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Intellectual Property</h2>
            <p className="mb-4">
              Users retain rights to their original content. By using our services, you grant Vidoren a license to process and modify your content solely for providing the requested services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
            <p className="mb-4">
              Vidoren is not liable for any indirect, incidental, or consequential damages arising from the use of our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Changes to Terms</h2>
            <p className="mb-4">
              We reserve the right to modify these terms at any time. Continued use of our services after changes constitutes acceptance of the new terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Contact Information</h2>
            <p className="mb-4">
              For questions about these terms, please contact us at:{" "}
              <a href="mailto:help.vidoren@gmail.com" className="text-indigo-600 hover:text-indigo-800">
                help.vidoren@gmail.com
              </a>
            </p>
          </section>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Vidoren. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfService;
